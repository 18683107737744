<template>
	<b-modal
		v-model="isModalOpen"
		id="klippa-one-ticket-from-file"
		ref="addNewExpenseModal"
		size="lg"
		:title="rendModalTitle"
		cancel-variant="light"
		@ok="handleOk"
		@show="initNewTicketRef"
		@shown="autoOpenCaptureModal"
		@cancel="handleClosedModalEvent"
		@closed="handleClosedModalEvent"
	>
		<b-form-group v-if="hasUserSelection" label-for="new-expense_ticket-ref" :label="FormMSG(70, 'Select Person')">
			<b-form-select
				size="md"
				v-model="selectedUser.value"
				value-field="value"
				text-field="name"
				disabled-field="notEnabled"
				:options="usersListOptionsMap"
				:disabled="isUsersListOptionsLoading || isUserSelectionDisabled"
				@change="handleSelectUser"
			/>
		</b-form-group>

		<div v-if="isExpenseLoading" class="full centered_content">
			<b-spinner v-show="true" />
		</div>
		<div v-else>
			<span v-if="isAnalyzingImage">
				<loading :active.sync="isAnalyzingImage" loader="bars" is-full-page />
			</span>
			<b-alert v-if="!isAnalyzingImage && isNoAnalyzeImgResult" show dismissible fade>
				{{ FormMSG(9875678, 'No billing information detected in this image') }}
			</b-alert>
			<b-form-group label-for="new-expense_ticket-ref" :label="FormMSG(12883, 'Invoice reference')">
				<b-form-input id="new-expense_ticket-ref" disabled :value="currNewTicketRef" />
			</b-form-group>

			<div class="full clearfix">
				<div class="centered_content full clearfix">
					<loading :active.sync="isUploadLoading" is-full-page />
				</div>
				<b-list-group v-if="!isUploadLoading" class="full">
					<b-list-group-item v-for="(item, i) in ocrResultsList" :key="i">
						<div class="full content_sb_middle">
							<div class="left">{{ item.description }}</div>
							<div class="right">
								<b>{{ rendCurrency(item.amountTotal) }}</b
								>&ensp;
								<small>{{ rendCurrency(item.amountVat) }}</small>
							</div>
						</div>
					</b-list-group-item>
				</b-list-group>
			</div>
		</div>

		<!-- multiple -->
		<div :key="capturePackageRefreshToken">
			<CapturesPackage
				v-if="!isOcrProcessFinished"
				ref="captureSystem"
				preview-mode="button"
				parent-type="expense_item"
				parent-sub-type="ticket"
				:generate-dynamic-parents-id-per-doc="multipleDoc ? initParentId : null"
				:can-upload-pdf="$isPwa()"
				:parent-id.sync="currNewTicketRef"
				:pictures="rendImagesListForPreviewer"
				:preview-modal-options="previewModalOptions"
				:wait-before-add-image.sync="isSettingUserToTicketLoading"
				:add-image-disabled.sync="isAnalyzingImage"
				:for-multiple-doc="multipleDoc"
				:for-splitting-tva="splitTva"
				@change="handleImgsTaken"
				@upload-image:loading="handleUploadImgStatus"
				@capture-modal:cancel="setKlippaStepCancel"
				@capture-modal:close="setKlippaStepCancel"
			/>
			<!-- :show-add-btn="$isPwa() || !hasAutoOpenCaptureModal" -->
		</div>
		<!-- modal warning if ticket is duplicated-->
		<duplicate-expenses-warning
			:ocr-message-error="ocrMessageError"
			v-model="ocrIssueModal"
			@expenses-warning:ok="ocrIssueModal = false"
		></duplicate-expenses-warning>
	</b-modal>
</template>

<script>
import { isNil, makeID } from '@/shared/utils';
import mapProps from '@/shared/vuePropsMapper';
import { getFileExtension, rendCurrency } from '@/shared/helpers';
import { getUsers } from '@/cruds/users.crud';
import { expenseItemsFromImageOrPdf, generateExpenseRef, deleteExpense, checkIfCarbonRowDuplicate } from '@/cruds/expense.crud';
import { EXPENSE_CARBON_ENTITY_TYPE } from '@/shared/constants';
import { store } from '@/store';
import DuplicateExpensesWarning from '@/components/Modals/DuplicateExpensesWarningModal';

import Loading from 'vue-loading-overlay';
import CapturesPackage from '@/components/Packages/Captures/components/Main';

import languageMessages from '@/mixins/languageMessages';

/**
 * @typedef {Object} TicketPayload
 * @property {String} [supplierName='']
 * @property {Array} [images=[]]
 */
const ticketPayloadDefault = { supplierName: '', images: [] };

/**
 * This component create a ticket and get klippa OCR analyse.
 *
 * @exports KlippaOneTicketFromPictureExpenseServiceComponent
 */
export default {
	/**
	 * @typedef {Object} Data
	 * @property {Boolean} [isAnalyzingImage=false] - is analyse from the ocr running ?.
	 * @property {Boolean} [isNoAnalyzeImgResult=false]
	 * @property {Boolean} [isUploadLoading=false]
	 * @property {TicketPayload} ticketPayloadDefault
	 * @property {Array} [ocrResultsList=[]]
	 * @property {Object} [error={ status: Boolean, code: Null }]
	 * @property {Boolean} [isExpenseLoading=false]
	 * @property {Object} previewModalOptions
	 */
	name: 'KlippaOneTicketFromPictureModalExpenseServiceComponent',
	components: { Loading, CapturesPackage, DuplicateExpensesWarning },
	mixins: [languageMessages],
	props: {
		...mapProps(['value', 'multipleDoc', 'splitTva', 'hasUserSelection'], {
			type: Boolean,
			default: false,
			required: false
		}),
		hasAutoOpenCaptureModal: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	data() {
		/** @type {Data} */
		return {
			isAnalyzingImage: false,
			isNoAnalyzeImgResult: false,
			isUploadLoading: false,
			ticketPayload: Object.assign({}, ticketPayloadDefault),
			ocrResultsList: [],
			error: { status: false, code: null },
			isExpenseLoading: true,
			previewModalOptions: {
				zIndex: 1041,
				hideLikeDislike: true,
				hideCommentButton: true,
				hideDisLikeButton: true
			},
			currNewTicketRef: 0,
			usersListOptions: [],
			isUsersListOptionsLoading: false,
			isUserSelectionDisabled: false,
			selectedUser: { name: '', value: 0 },
			isSettingUserToTicketLoading: false,
			isOcrProcessFinished: false,
			ocrMessageError: '',
			ocrIssueModal: false,
			capturePackageRefreshToken: `capture_${makeID(10)}`
		};
	},
	computed: {
		isModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.value;
			},
			/**
			 * @param {!Boolean} status
			 */
			set(status) {
				this.$emit('input', status);
			}
		},

		/**
		 * @return {Array}
		 */
		rendImagesListForPreviewer() {
			/** @type {Array} imgs */
			const imgs = this.ticketPayload.images;
			if (isNil(imgs) || imgs.length === 0) return [];

			return imgs.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));
		},

		/**
		 * @return {String}
		 */
		rendModalTitle() {
			return this.multipleDoc
				? this.FormMSG(987786, 'Create multiple ticket from pictures (one picture/ticket)')
				: this.FormMSG(439234, 'Create one ticket from pictures');
		},

		usersListOptionsMap() {
			this.usersListOptions= this.usersListOptions.map((_user) => ({
				value: _user.id,
				name: _user.fullName
			}));
			return this.usersListOptions.filter((item) => +item.value !== +store.state.myProfile.id);
		}
	},
	methods: {
		async handleClosedModalEvent() {
			await deleteExpense(this.currNewTicketRef);
			if (this.ocrResultsList.length > 0) {
				await this.deleteCurrentOcrListInTheDB(this.ocrResultsList);
			}
			this.$emit('closed', true);
		},

		async setKlippaStepCancel() {
			this.capturePackageRefreshToken = `capture_${makeID(10)}`;
			this.isOcrProcessFinished = false;
		},

		/**
		 * @return {Feunction}
		 */
		async initNewTicketRef() {
			this.ocrResultsList = [];
			this.isExpenseLoading = true;
			this.currNewTicketRef = await this.initParentId('init');
			this.setUser();
			this.getAllUsers();
			this.isExpenseLoading = false;
		},

		async getAllUsers() {
			if (!this.hasUserSelection) return;
			this.isUsersListOptionsLoading = true;
			try {
				this.usersListOptions = await getUsers({ myProject: true });
			} catch (error) {
				console.error({ error });
			}
			this.isUsersListOptionsLoading = false;
		},

		/**
		 * @param {Object} [payload={}]
		 */
		async setSelectedUserToTicket(payload = {}) {
			this.isSettingUserToTicketLoading = true;
			await this.$store.dispatch('expenses/setNewExpense', {
				id: isNil(payload.ticketId) ? this.currNewTicketRef : payload.ticketId,
				expense: {
					type: 0,
					validated: 0
				},
				userId: isNil(payload.userId) ? this.selectedUser.value : payload.userId
			});
			this.isSettingUserToTicketLoading = false;
		},

		/**
		 * @param {Event} $event
		 */
		async handleOk($event) {
			if (this.splitTva) await deleteExpense(this.currNewTicketRef);
			this.$emit('submited', this.ocrResultsList);
		},

		setUser() {
			const { id } = store.state.myProfile || {};
			this.selectedUser.value = id;
			this.setSelectedUserToTicket({ userId: id });
			this.$emit('changeUser', id);
		},

		handleSelectUser(value) {
			// this.selectedUser.value = value;
			this.setSelectedUserToTicket({ userId: value });
			this.$emit('changeUser', value);
		},

		/**
		 * @param {?String} type
		 * @return {Number|String}
		 */
		async initParentId(type = null) {
			const { id } = await generateExpenseRef({ type: 0 });
			if (type !== 'init') this.setSelectedUserToTicket({ ticketId: id, userId: this.selectedUser.value });
			return id;
		},

		getErrorCode(e) {
			const error = e.message.match(new RegExp('[0-9]{3}', 'g'));
			this.error.status = true;
			if (!isNil(error) && error.length === 1) this.error.code = error[0];
		},

		/**
		 * auto fire of the capture system except on mobile (pwa) device due to permission issue
		 */
		autoOpenCaptureModal() {
			if (!this.isModalOpen || !this.hasAutoOpenCaptureModal) return;
			this.$nextTick(() => {
				if (isNil(this.$refs.captureSystem) || this.$isPwa()) return;
				this.$refs.captureSystem.openImagesModal();
			});
		},

		/**
		 * @param {!Array} images
		 */
		async handleImgsTaken(images) {
			if (isNil(images)) return;
			try {
				this.isAnalyzingImage = true;

				/**
				 * remove ext from xid files..
				 * @type {Array} cleanImgs
				 */
				// const cleanImgs = images.map((_) => _.replace(/\.[^\.]*$/, ''));
				const cleanNewImagesList = images.filter((item) => !this.ocrResultsList.includes(item)).map((i) => i.replace(/\.[^\.]*$/, ''));
				const _l = await expenseItemsFromImageOrPdf(cleanNewImagesList, {
					multipleExpenseItem: this.multipleDoc,
					splitTVA: this.splitTva
				});
				if (!this.splitTva) {
					const checkDuplicate = await this.checkIfTicketAlreadyUploaded(_l);
					if (checkDuplicate) {
						this.ocrMessageError = this.FormMSG(10, 'DUPLICATE ENTRY: It seems like similar data has already been used in another entry.');
						this.ocrIssueModal = true;
					}
				}
				this.isUserSelectionDisabled = true;
				this.ocrResultsList = [...this.ocrResultsList, ..._l];
				this.isOcrProcessFinished = true;
				this.$emit('enable-submit', true);
			} catch (e) {
				console.error({ e });
			}
			this.isAnalyzingImage = false;
		},

		/**
		 * @param {!Array} listToCheck
		 */
		async checkIfTicketAlreadyUploaded(listToCheck) {
			let isUploaded = false;
			for (const expense of listToCheck) {
				const isDataDuplicated = await checkIfCarbonRowDuplicate(expense.id, EXPENSE_CARBON_ENTITY_TYPE);
				if (isDataDuplicated) isUploaded = true;
			}
			return isUploaded;
		},

		/**
		 * @param {!Array} listToDelete
		 */
		async deleteCurrentOcrListInTheDB(listToDelete) {
			for (const expense of listToDelete) {
				try {
					await deleteExpense(expense.id);
				} catch (error) {
					console.log(error);
				}
			}
		},

		/**
		 * @param {Boolean} status
		 */
		handleUploadImgStatus(status) {
			this.isUploadLoading = true;
			this.$emit('upload-image-loading:status', status);
		},

		/**
		 * @param {String|Number|Float}! value
		 * @return {String}
		 */
		rendCurrency(value) {
			if (isNil(value)) return '/';
			return rendCurrency(value);
		}
	}
};
</script>
