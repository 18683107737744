var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "addNewExpenseModal",
      attrs: {
        id: "klippa-one-ticket-from-file",
        size: "lg",
        title: _vm.rendModalTitle,
        "cancel-variant": "light",
      },
      on: {
        ok: _vm.handleOk,
        show: _vm.initNewTicketRef,
        shown: _vm.autoOpenCaptureModal,
        cancel: _vm.handleClosedModalEvent,
        closed: _vm.handleClosedModalEvent,
      },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _vm.hasUserSelection
        ? _c(
            "b-form-group",
            {
              attrs: {
                "label-for": "new-expense_ticket-ref",
                label: _vm.FormMSG(70, "Select Person"),
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  size: "md",
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "notEnabled",
                  options: _vm.usersListOptionsMap,
                  disabled:
                    _vm.isUsersListOptionsLoading ||
                    _vm.isUserSelectionDisabled,
                },
                on: { change: _vm.handleSelectUser },
                model: {
                  value: _vm.selectedUser.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser, "value", $$v)
                  },
                  expression: "selectedUser.value",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isExpenseLoading
        ? _c(
            "div",
            { staticClass: "full centered_content" },
            [
              _c("b-spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: true,
                    expression: "true",
                  },
                ],
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.isAnalyzingImage
                ? _c(
                    "span",
                    [
                      _c("loading", {
                        attrs: {
                          active: _vm.isAnalyzingImage,
                          loader: "bars",
                          "is-full-page": "",
                        },
                        on: {
                          "update:active": function ($event) {
                            _vm.isAnalyzingImage = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isAnalyzingImage && _vm.isNoAnalyzeImgResult
                ? _c(
                    "b-alert",
                    { attrs: { show: "", dismissible: "", fade: "" } },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              9875678,
                              "No billing information detected in this image"
                            )
                          ) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "new-expense_ticket-ref",
                    label: _vm.FormMSG(12883, "Invoice reference"),
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "new-expense_ticket-ref",
                      disabled: "",
                      value: _vm.currNewTicketRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "full clearfix" },
                [
                  _c(
                    "div",
                    { staticClass: "centered_content full clearfix" },
                    [
                      _c("loading", {
                        attrs: {
                          active: _vm.isUploadLoading,
                          "is-full-page": "",
                        },
                        on: {
                          "update:active": function ($event) {
                            _vm.isUploadLoading = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isUploadLoading
                    ? _c(
                        "b-list-group",
                        { staticClass: "full" },
                        _vm._l(_vm.ocrResultsList, function (item, i) {
                          return _c("b-list-group-item", { key: i }, [
                            _c(
                              "div",
                              { staticClass: "full content_sb_middle" },
                              [
                                _c("div", { staticClass: "left" }, [
                                  _vm._v(_vm._s(item.description)),
                                ]),
                                _c("div", { staticClass: "right" }, [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.rendCurrency(item.amountTotal))
                                    ),
                                  ]),
                                  _vm._v(" \n\t\t\t\t\t\t\t"),
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(_vm.rendCurrency(item.amountVat))
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ])
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "div",
        { key: _vm.capturePackageRefreshToken },
        [
          !_vm.isOcrProcessFinished
            ? _c("CapturesPackage", {
                ref: "captureSystem",
                attrs: {
                  "preview-mode": "button",
                  "parent-type": "expense_item",
                  "parent-sub-type": "ticket",
                  "generate-dynamic-parents-id-per-doc": _vm.multipleDoc
                    ? _vm.initParentId
                    : null,
                  "can-upload-pdf": _vm.$isPwa(),
                  "parent-id": _vm.currNewTicketRef,
                  pictures: _vm.rendImagesListForPreviewer,
                  "preview-modal-options": _vm.previewModalOptions,
                  "wait-before-add-image": _vm.isSettingUserToTicketLoading,
                  "add-image-disabled": _vm.isAnalyzingImage,
                  "for-multiple-doc": _vm.multipleDoc,
                  "for-splitting-tva": _vm.splitTva,
                },
                on: {
                  "update:parentId": function ($event) {
                    _vm.currNewTicketRef = $event
                  },
                  "update:parent-id": function ($event) {
                    _vm.currNewTicketRef = $event
                  },
                  "update:waitBeforeAddImage": function ($event) {
                    _vm.isSettingUserToTicketLoading = $event
                  },
                  "update:wait-before-add-image": function ($event) {
                    _vm.isSettingUserToTicketLoading = $event
                  },
                  "update:addImageDisabled": function ($event) {
                    _vm.isAnalyzingImage = $event
                  },
                  "update:add-image-disabled": function ($event) {
                    _vm.isAnalyzingImage = $event
                  },
                  change: _vm.handleImgsTaken,
                  "upload-image:loading": _vm.handleUploadImgStatus,
                  "capture-modal:cancel": _vm.setKlippaStepCancel,
                  "capture-modal:close": _vm.setKlippaStepCancel,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("duplicate-expenses-warning", {
        attrs: { "ocr-message-error": _vm.ocrMessageError },
        on: {
          "expenses-warning:ok": function ($event) {
            _vm.ocrIssueModal = false
          },
        },
        model: {
          value: _vm.ocrIssueModal,
          callback: function ($$v) {
            _vm.ocrIssueModal = $$v
          },
          expression: "ocrIssueModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }